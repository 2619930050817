<template>
	<div class="list">
		<div 
		:class="{'listitem':true,'page1': page === 1 }"
		 v-for="item in list"
		  @click="toDetail(item.id)" 
			 :key="item.id" >
			<div class="coverbox">

				<img class="cover" :src="item.cover" alt="">
			</div>
			<div class="content">
				<div class="topcon">
					<div class="title">{{ item.title }}</div>
					<div class="summary">{{ item.summary }}</div>
				</div>
				<div class="create_at"  style="color:#A0A0A0;font-size:12px" >发布时间：{{ item.created_at }}</div>
			</div>

		</div>
		<div class="page">


			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
				:page-size="size" background layout="prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import { list } from '@/api/website/zhaoshang'
export default {
	name: 'PcWebsiteIntro',

	data() {
		return {
			list: [],
			page: 1,
			size: 8,
			total: 0,
		}
	},

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	watch: {
		$route: {
			handler(v) {
				this.getlist()
			}
		}
	},



	mounted() {
		this.getlist()
	},

	methods: {

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/zhaoshang/' + id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},
		

		handleCurrentChange(val) {

			// console.log(`当前页: ${val}`);
			this.page = val
			this.getlist()
		},

		getlist() {
			list({ site_id: this.site.id, page: this.page, size: this.size }).then(res => {
				this.list = res.data.list
				this.total = res.data.total
			})
		}
	},
};
</script>

<style lang="scss" scoped>
.page {
	margin-top: 30px;
	margin-bottom: 30px;
}

.list {

	height: auto;
	width: 790px;
	padding: 0 20px;

	.listitem {
		cursor: pointer;
		width: 790px;
		height: 160px;
		display: flex;
		align-items: center;
		border-bottom: solid 1px #F2F2F2;

		.coverbox {
			width: 180px;
			height: 120px;

			.cover {
				width: 180px;
				height: 120px;
				object-fit: cover;
			}
		}


		.content {
			width: 600px;
			height: 120px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			margin-left: 10px;

			.topcon {
				width: 600px;

				.title {
					text-align: left;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #000000;
					line-height: 28px;
					overflow: hidden; //超出隐藏
					white-space: nowrap; //不折行
					text-overflow: ellipsis; //溢出显示省略号

				}


				.summary {
					text-align: left;
					height: 40px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #848484;
					line-height: 20px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden; //溢出内容隐藏
					text-overflow: ellipsis; //文本溢出部分用省略号表示
					display: -webkit-box; //特别显示模式
					-webkit-line-clamp: 2; //行数
					line-clamp: 2;
					-webkit-box-orient: vertical; //盒子中内容竖直排列
				}

			}
		}


	}

	.page1:first-child {
		width: 790px;
		height: 232px;
		display: flex;
		align-items: center;
		border-bottom: solid 1px #F2F2F2;

		.coverbox {
			width: 320px;
			height: 192px;

			.cover {
				width: 320px;
				height: 192px;
				object-fit: cover;
			}
		}


		.content {
			width: 454px;
			height: 192px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			margin-left: 10px;

			.topcon {
				width: 454px;

				.title {
					text-align: left;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #000000;
					line-height: 28px;
					overflow: hidden; //超出隐藏
					white-space: nowrap; //不折行
					text-overflow: ellipsis; //溢出显示省略号

				}


				.summary {
					text-align: left;
					height: 40px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #848484;
					line-height: 20px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden; //溢出内容隐藏
					text-overflow: ellipsis; //文本溢出部分用省略号表示
					display: -webkit-box; //特别显示模式
					-webkit-line-clamp: 6; //行数
					line-clamp: 6;
					-webkit-box-orient: vertical; //盒子中内容竖直排列
				}

			}
		}


	}


}
</style>